<template><div></div></template>

<script>
import L from "leaflet";
import distance from "@/util/distanceLine.js";
export default {
  props: ["map"],
  data() {
    return {
      is_active: false,
      customIcon: null,
      polylines: [],
      is_number: 0,
      popupDistance: "0.000",
    };
  },
  methods: {
    showLine() {
      this.customIcon = L.divIcon({
        className: "custom-marker",
        html: '<div style="background-color: #ffff; width: 13px; height: 13px; border: 1px solid black;"></div>',
        iconSize: [10, 10],
        iconAnchor: [10, 10],
      });
      this.is_active = true;
      this.map.on("click", (e) => {
        this.is_active ? this.addLine(e) : "";
      });
    },
    addLine(e) {
      if (this.polylines.length == 0) {
        this.polylines.push({
          markerObject: [],
          polylineObject: [],
          popupObject: [],
          polyline: [
            { id: ++this.is_number, coords: [e.latlng.lat, e.latlng.lng] },
          ],
        });

        let idPolyline = this.is_number;

        const marker = L.marker(
          [
            this.polylines[0].polyline[0].coords[0],
            this.polylines[0].polyline[0].coords[1],
          ],
          {
            draggable: true,
            icon: this.customIcon,
          }
        ).addTo(this.map);

        this.polylines[0].markerObject.push(marker);

        const polyline = L.polyline(this.polylines[0].polyline[0].coords[0], {
          color: "green",
          width: 100,
        }).addTo(this.map);

        this.polylines[0].polylineObject = polyline;

        const popup = L.popup()
          .setLatLng([
            this.polylines[0].polyline[0].coords[0],
            this.polylines[0].polyline[0].coords[1],
          ])
          .setContent(`<p>${this.popupDistance} km</p>`)
          .openOn(this.map);

        this.polylines[0].popupObject.push(popup);

        this.attachDragEvent(marker, idPolyline);
      } else {
        const polyLine = this.polylines[0];
        polyLine.polyline.push({
          id: ++this.is_number,
          coords: [e.latlng.lat, e.latlng.lng],
        });
        let id = this.is_number;
        this.popupDistance = distance(polyLine.polyline).distance.toFixed(2);

        polyLine.polylineObject.setLatLngs(
          polyLine.polyline.map((coord) => coord.coords)
        );

        const marker = L.marker([e.latlng.lat, e.latlng.lng], {
          draggable: true,
          icon: this.customIcon,
        }).addTo(this.map);

        polyLine.markerObject.push(marker);

        const popup = L.popup()
          .setLatLng([e.latlng.lat, e.latlng.lng])
          .setContent(`<p>${this.popupDistance} km</p>`)
          .openOn(this.map);

        this.polylines[0].popupObject.push(popup);

        this.attachDragEvent(marker, id);
      }
    },
    attachDragEvent(marker, index) {
      marker.on("drag", (e) => {
        const newPos = e.target.getLatLng();

        this.polylines[0].polyline.forEach((e) => {
          if (e.id == index) e.coords = [newPos.lat, newPos.lng];
        });

        this.polylines[0].polylineObject.setLatLngs(
          this.polylines[0].polyline.map((coord) => coord.coords)
        );
      });
    },
    clearLine() {
      (this.is_number = 0), (this.popupDistance = "0.000");
      this.is_active = false;
      if (this.polylines[0].markerObject)
        this.polylines[0].markerObject.forEach((e) => {
          e.remove();
        });
      if (this.polylines[0].polyline.length > 0)
        this.polylines[0].polyline = [];
      if (this.polylines[0].polylineObject)
        this.polylines[0].polylineObject.remove();
      if (this.polylines[0].popupObject)
        this.polylines[0].popupObject.forEach((e) => {
          e.remove();
        });
    },
  },
};
</script>
