<template>
  <div v-if="editHouseData" class="text-white">
    <div v-if="isActiveentrance">
      <div class="flex justify-between mx-2 mt-1">
        <p class="text-2xl font-semibold">Редактировать дом</p>
        <button
          @click="
            copy(`Дом: ${editHouseData.id}  Улица: ${editHouseData.streetCode}`)
          "
          title="Скопировать ID дома и улицы"
          class="bg-white rounded-[3px] my-2 hover:bg-slate-300"
        >
          <img src="@/assets/img/file-copy.svg" class="w-5 h-5" alt="" />
        </button>
      </div>
      <div class="my-4 px-3">
        <div class="flex justify-between">
          <label class="text-sm mr-2">Номер дома</label>
          <input
            v-model="editHouseData.number"
            @change="filterEditHome()"
            class="!rounded-[3px] border w-[200px] text-black px-2 py-1 h-[30px]"
            type="text"
          />
        </div>
        <div class="flex justify-between mt-2">
          <label for="">Улица</label>
          <v-select
            class="rounded-[5px] w-[200px] text-black bg-white text-[15px]"
            :options="streets"
            :reduce="(street) => street.streetCode"
            v-model="editHouseData.street"
            @change="filterEditHome()"
            label="displayName"
          >
            <template v-slot:no-options>
              <div>Нет данные в базе</div>
            </template>
          </v-select>
        </div>
        <div class="flex justify-between mt-2">
          <label for="" class="mr-2">Координаты</label>
          <button
            class="pl-1 truncate cursor-not-allowed rounded-[3px] bg-black text-white border-black border-[1px] w-[200px] h-[30px]"
          >
            {{ coordinate }}
          </button>
        </div>
        <div class="text-center mt-5">
          <p>
            <button
              @click="createEntrance()"
              class="w-[100px] hover:bg-slate-300 bg-white border-white border-[1px] text-black rounded-[3px]"
            >
              Подъезды({{ entrance.length }})
            </button>
          </p>
        </div>
        <div class="flex justify-between">
          <button class="buttonCustom mt-3" @click="closeClear()">
            Отмена
          </button>
          <button
            class="buttonCustom mt-3"
            v-if="active"
            @click="createHome()"
            title="Копировать"
          >
            <img src="@/assets/img/file-copy.svg" class="w-5 h-5" alt="" />
          </button>
          <button
            class="buttonCustom mt-3"
            :class="blockHomeDisabled ? 'disabled' : ''"
            :disabled="blockHomeDisabled"
            @click="blockHome()"
          >
            Блокировать
          </button>
          <button
            class="buttonCustom mt-3"
            @click="editHome()"
            :class="active ? 'disabled' : ''"
            :disabled="active"
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="!isActiveentrance">
      <div class="min-w-[350px] p-3">
        <p class="text-[35px]">Подъезды</p>
        <p>{{ editHouseData.street }} {{ editHouseData.number }}</p>
        <div v-if="entrance.length > 0" class="overflow-auto max-h-[400px]">
          <table>
            <thead>
              <tr class="flex items-center">
                <th
                  class="mr-7"
                  v-for="entrance in entranceTable"
                  :key="entrance.key"
                >
                  {{ entrance.value }}
                </th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr
                v-for="(entrances, index) in entrance"
                :key="entrances.idCustom"
                class="border-y-[1px] border-white"
              >
                <td class="flex items-center my-[10px]">
                  <input
                    class="w-[40px] mr-[20px] !border-[1px] !rounded-[3px]"
                    type="text"
                    :value="index + 1"
                    disabled
                  />
                  <label
                    class="rounded-l-[3px] border-white bg-white p-1"
                    for=""
                    >подъезд №</label
                  >
                  <input
                    class="w-[90px] h-[34px] !border-[1px] !rounded-r-[3px] border-white"
                    disabled
                    type="text"
                    v-model="entrances.name"
                    @change="filterEntrance()"
                  />
                  <button
                    @click="deleteEntrance(index, entrances)"
                    class="bg-white border-white rounded-[3px] border-[1px] p-1 ml-[20px]"
                  >
                    <img src="../../assets/img/trash.svg" alt="" srcset="" />
                  </button>
                </td>
              </tr>
              <p v-if="addEntranceActive" class="message-error mt-2">
                У подъезда нет координаты (долгота ширина).
              </p>
            </tbody>
          </table>
        </div>
        <div class="flex justify-between items-center mt-5">
          <div>
            <button class="buttonCustom" @click="back()">Назад</button>
          </div>
          <div>
            <button
              @click="addEntrance"
              :class="addEntranceActive ? 'disabled' : ''"
              :disabled="addEntranceActive"
              class="buttonCustom w-[30px]"
            >
              +
            </button>
            <button
              class="buttonCustom ml-2"
              @click="saveEntrance"
              :class="activeEntrance ? 'disabled' : ''"
              :disabled="activeEntrance"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "selectHome",
    "village_id",
    "coordinateDragendMarker",
    "addEntranceActive",
  ],
  data() {
    return {
      editHouseData: null,
      streets: [],
      coordinate: null,
      active: true,
      blockHomeDisabled: false,
      entrance: [],
      isActiveentrance: true,
      entranceTable: [
        { key: "order", value: "Порядок" },
        { key: "name", value: "Название" },
      ],
      activeEntrance: true,
      idHome: null,
      deleteIdEntrance: [],
    };
  },
  methods: {
    editformHome(id) {
      this.idHome = id;
      this.getEntrance(id);
      this.$http
        .get(`v1/points/${id}`)
        .then((res) => {
          this.editHouseData = res.data;
          this.coordinate = `${res.data.lat}, ${res.data.lng}`;
          this.getAllStreets();
          localStorage.setItem("filterEditHome", JSON.stringify(res.data));
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEntrance(id) {
      this.$http
        .get(`v1/entrance/point/${id}`)
        .then((res) => {
          this.entrance = res.data;
          localStorage.setItem("entrance", JSON.stringify(res.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    copy(params) {
      navigator.clipboard.writeText(params);
    },
    getAllStreets() {
      this.$http
        .get(`v1/polygons/${this.village_id}/streets`)
        .then((res) => {
          this.streets = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createHome() {
      console.log("createHome");
    },
    filterEditHome() {
      if (
        localStorage.getItem("filterEditHome") ===
        JSON.stringify(this.editHouseData)
      ) {
        this.active = true;
      } else {
        this.active = false;
      }
    },
    filterEntrance() {
      if (localStorage.getItem("entrance") === JSON.stringify(this.entrance)) {
        this.activeEntrance = true;
      } else {
        this.activeEntrance = false;
      }
    },
    blockHome() {
      this.blockHomeDisabled = true;
      this.$http
        .put(`v1/points/${this.editHouseData.id}/block`)
        .then((res) => {
          this.$toast.open({
            message: `Ок ${res}`,
            type: "success",
            position: "top",
          });
          this.closeClear("block", res.data, this.editHouseData.id);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.blockHomeDisabled = false;
        });
    },
    editHome() {
      this.$http
        .patch(`v1/points/${this.editHouseData.id}`, this.editHouseData)
        .then((res) => {
          this.$toast.open({
            message: `Дом успешно изменен!`,
            type: "success",
            position: "top",
          });
          this.closeClear("edit", res.data, this.editHouseData.id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createEntrance() {
      this.isActiveentrance = false;
      this.$emit("createEntrance", this.entrance);
    },

    addEntrance() {
      let number = this.entrance.length + 1;
      this.entrance.push({ idCustom: number, lat: "", lng: "", name: number });
      this.$emit("addEntrance", number);
      this.filterEntrance();
    },
    deleteEntrance(index, data) {
      const confir = confirm("Удалить подъезд?");
      if (confir) {
        this.entrance.splice(index, 1);
        this.filterEntrance();
        this.entrance.forEach((e, i) => {
          e.idCustom = ++i;
          e.name = i;
        });
        this.$emit("createEntrance", this.entrance);
        if (data.id) {
          this.deleteIdEntrance.push(data.id);
        }
      }
    },
    editCoordsEntrance(data, coords) {
      this.entrance.forEach((elem) => {
        if (elem.id && data == elem.id) {
          elem.lat = coords.lat;
          elem.lng = coords.lng;
        } else if (!elem.id && data == elem.idCustom) {
          elem.lat = coords.lat;
          elem.lng = coords.lng;
        }
      });
    },
    async saveEntrance() {
      this.activeEntrance = true;
      try {
        if (this.deleteIdEntrance.length > 0) {
          await this.deleteEntranceId();
        }

        for (const entra of this.entrance) {
          entra.name = String(entra.name);
          if (!entra.id) {
            await this.addEntrances(entra);
          } else {
            await this.editEntrances(entra);
          }
        }

        this.isActiveentrance = true;
        this.getEntrance(this.idHome);
        this.$emit("clearEntranceActive");
      } catch (error) {
        console.log(error);
      } finally {
        this.activeEntrance = false;
      }
    },

    async editEntrances(entra) {
      try {
        const res = await this.$http.patch(
          `v1/entrance/point/${entra.id_address}/${entra.id}`,
          entra
        );
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    async addEntrances(entra) {
      try {
        const res = await this.$http.post(
          `v1/entrance/point/${this.idHome}`,
          entra
        );
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },

    async deleteEntranceId() {
      const deletePromises = this.deleteIdEntrance.map((isDelete, key) =>
        this.$http
          .delete(`v1/entrance/point/${this.idHome}/${isDelete}`)
          .then((res) => {
            this.deleteIdEntrance.splice(key, 1);
            console.log(res.data);
          })
          .catch((error) => console.log(error))
      );

      await Promise.all(deletePromises);
    },
    back() {
      if (!this.activeEntrance) {
        const confir = confirm(
          "Все несохранённые данные будут потеряны. Отменить изменения?"
        );
        if (confir) {
          this.isActiveentrance = true;
          this.getEntrance(this.idHome);
          this.$emit("clearEntranceActive");
        }
      } else {
        this.isActiveentrance = true;
        this.$emit("createEntrance", this.entrance);
      }
    },
    closeClear(param, data, id) {
      (this.editHouseData = null),
        (this.streets = []),
        (this.coordinate = null),
        (this.entrances = 0),
        (this.active = true),
        (this.blockHomeDisabled = false);
      this.$emit("clearModel", param, data, id);
      localStorage.removeItem("entrance");
      localStorage.removeItem("filterEditHome");
    },
  },
  watch: {
    coordinateDragendMarker(newValue) {
      this.activeEntrance = false;
      this.editHouseData.lat = newValue.lat;
      this.editHouseData.lng = newValue.lng;
      this.coordinate = `${newValue.lat}, ${newValue.lng}`;
      let leafMarker = JSON.parse(localStorage.getItem("filterEditHome"));
      leafMarker.lat = newValue.lat;
      leafMarker.lng = newValue.lng;
      localStorage.setItem("filterEditHome", JSON.stringify(leafMarker));
      this.filterEditHome();
    },
  },
};
</script>

<style scoped>
.buttonCustom {
  @apply border-[1px] border-white bg-white rounded-[3px] text-black p-1;
}
.disabled {
  @apply bg-black text-white border-black;
}
</style>
