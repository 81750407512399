export default function distance(arr) {
  let totalDistance = 0;

  for (let i = 0; i < arr.length - 1; i++) {
    totalDistance += haversine(
      arr[i].coords[0],
      arr[i].coords[1],
      arr[i + 1].coords[0],
      arr[i + 1].coords[1]
    );
  }

  return {
    distance: totalDistance,
  };
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function haversine(lat1, lon1, lat2, lon2) {
  const R = 6371; // Радиус Земли в километрах

  // Преобразование градусов в радианы
  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);

  // Формула Хаверсайна
  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Расстояние в километрах
  return R * c;
}
