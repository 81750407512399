import deleteLocalStorage from "@/util/deleteLocalStorage";

export default function checkToken(axiosIns) {
  const refreshInterval = 1200000;
  const checkInterval = 300000;

  const checkAndRefresh = () => {
    let data = JSON.parse(localStorage.getItem("refresh_token"));
    if (!data) return;
    if (isTokenExpired(data)) {
      deleteLocalStorage();
      return;
    }
  };

  checkAndRefresh();

  setInterval(checkAndRefresh, checkInterval);

  setInterval(() => {
    refreshToken(axiosIns);
  }, refreshInterval);
}

const isTokenExpired = (data) => {
  const expDate = new Date(data.expDate);
  const now = new Date();
  return now >= expDate;
};

function refreshToken(axiosIns) {
  let login_data = JSON.parse(localStorage.getItem("login_data"));

  if (!login_data) return;

  axiosIns
    .post("v1/auth/login", login_data)
    .then((res) => {
      let refreshTokenData = JSON.parse(localStorage.getItem("refresh_token"));
      refreshTokenData.expDate = res.data.expDate;
      refreshTokenData.token = res.data.token;
      localStorage.setItem("refresh_token", JSON.stringify(refreshTokenData));
      localStorage.setItem("user-token", res.data.token);
      axiosIns.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token}`;
    })
    .catch((error) => {
      console.log(error);
    });
}
